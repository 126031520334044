/** @jsx jsx */
import { jsx, Container, Box, Themed, Grid } from "theme-ui";
import { graphql } from "gatsby";
import { useState, useContext } from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Hero from "../components/Hero";
import ContentfulComponent from "../components/ContentfulComponent";
import RichText2 from "../components/RichText2";
import BreadCrumbs from "../components/BreadCrumbs";
import Spacer from "../components/Spacer";
import Share from "../components/Share";
import ContentTopicLinks from "../components/ContentTopicLinks";
import RelatedContent from "../components/RelatedContent";
import Helmet from "react-helmet";
import { createImageUrl, getUrlFromSlug } from "../utils/utils";
import { useSitePages } from "../hooks/useSitePages";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import { MDXRenderer } from "gatsby-plugin-mdx";

const ProductTemplate = ({ data, pageContext }) => {
  const nodes = useSitePages();
  const {
    pageType,
    title,
    navigationTitle,
    slug,
    parentPage,
    metaDescription,
    metaTitle,
    navigationIcon,
    body,
    hero,
    uspLiftUps,
    liftUps,
    liftupLinks,
    attachements,
    contentArea,
    contentAreaBottom,
    hidden,
    noIndex,
    sharePage,
    enableAbTesting,
    showNpsPopup,
    canonicalTag,
    contentTopics,
  } = data.contentfulProductPage;

  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    defaultImage,
    author,
    social,
    organization,
  } = useSiteMetadata();

  let metaImage =
    hero && hero.image && hero.image.gatsbyImageData
      ? createImageUrl(hero.image.gatsbyImageData)
      : siteUrl + defaultImage;

  const currentPage = {
    slug,
    navigationTitle,
  };
  const metadataTitle = hero && hero.title ? hero.title : title;

  const seoMetadataTitle = metaTitle ? metaTitle : defaultTitle;

  const metadataDescription =
    metaDescription &&
    metaDescription.internal &&
    metaDescription.internal.content
      ? metaDescription.internal.content
      : defaultDescription;
  const { node_locale: locale, language } = pageContext;

  const uspLiftUpsdata = uspLiftUps?.data?.internal?.content ? JSON.parse(uspLiftUps.data.internal.content) : {};

  return (
    <Layout
      pageContext={pageContext}
      pageType={pageType}
      enableAbTesting={enableAbTesting}
      showNpsPopup={showNpsPopup}
    >
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Product",
            sku: title,
            review: {
              "@type": "Review",
              name: body && body.childMdx && body.childMdx.excerpt,
              author: author,
            },
            brand: {
              "@type": "Organization",
              name: author,
            },
            manufacturer: {
              "@type": "Organization",
              name: author,
            },
            image: {
              "@type": "ImageObject",
              url: `${metaImage}`,
            },
            logo: {
              "@type": "ImageObject",
              url: `${siteUrl}${defaultImage}`,
            },
            url: siteUrl + getUrlFromSlug(currentPage.slug, nodes),
            category: parentPage
              ? parentPage.navigationTitle
              : currentPage.navigationTitle,
            name: title,
            slogan: metadataTitle,
            title: seoMetadataTitle,
            description: metadataDescription,
          })}
        </script>
      </Helmet>
      <SEO
        lang={pageContext.language}
        title={title}
        description={metadataDescription}
        metadataTitle={seoMetadataTitle}
        image={metaImage}
        paths={pageContext.paths}
        hidden={hidden}
        noIndex={noIndex}
        canonicalTag={canonicalTag}
      />
      <Container>
        <BreadCrumbs
          slug={slug}
          navigationTitle={navigationTitle}
          parentPage={parentPage}
          language={language}
        />
      </Container>
      <Container id="content">
        {hero && (
          <Box sx={{ mx: [-3, -4, 0] }}>
            <Hero {...hero} />
          </Box>
        )}
        {uspLiftUps && (
          <ContentfulComponent
            template={uspLiftUps.template}
            data={uspLiftUpsdata}
            locale={locale}
            language={language}
          />
        )}
        {body && (
          <Box variant="layout.lead">
            <Spacer />
            <MDXRenderer>{body.childMdx.body}</MDXRenderer>
          </Box>
        )}
      </Container>
      <Container variant="layout.narrow">
        {contentArea && contentArea.raw && (
          <RichText2
            content={contentArea}
            locale={locale}
            language={language}
          />
        )}
      </Container>
      <Container>
        {contentAreaBottom && contentAreaBottom.raw && (
          <RichText2
            content={contentAreaBottom}
            locale={locale}
            language={language}
          />
        )}
      </Container>
      {contentTopics && (
        <Container>
          <RelatedContent
            contentTopics={contentTopics}
            locale={locale}
            currentPageSlug={slug}
            pageType={pageType}
          />
          <ContentTopicLinks contentTopics={contentTopics} />
        </Container>
      )}
      {sharePage ? (
        <Container
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <Share language={language} />
        </Container>
      ) : (
        ""
      )}
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!) {
    contentfulProductPage(id: { eq: $id }) {
      id
      contentful_id
      __typename
      pageType
      title
      navigationTitle
      sharePage
      parentPage {
        ... on ContentfulThemePage {
          slug
          navigationTitle
        }
        ... on ContentfulPage {
          slug
          navigationTitle
        }
        ... on ContentfulProductPage {
          slug
          navigationTitle
        }
      }
      slug
      hero {
        id
        contentful_id
        __typename
        title
        type
        description
        node_locale
        image {
          gatsbyImageData
        }
        linkText
        link {
          ... on ContentfulFrontpage {
            slug
          }
          ... on ContentfulPage {
            slug
          }
          ... on ContentfulProductPage {
            slug
          }
        }
        target
        analyticsAction
        analyticsId
      }
      uspLiftUps {
        ...fragmentContentfulComponent
      }
      canonicalTag
      metaKeywords
      metaDescription {
        internal {
          content
        }
      }
      metaTitle
      navigationIcon {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      body {
        childMdx {
          id
          body
          excerpt
        }
      }
      hidden
      contentArea {
        raw
        references {
          ... on Node {
            ...fragmentContentfulFrontpage
          }
          ... on Node {
            ...fragmentContentfulThemePage
          }
          ... on Node {
            ...fragmentContentfulProductPage
          }
          ... on Node {
            ...fragmentContentfulPage
          }
          ... on Node {
            ...fragmentContentfulContentHubThemePage
          }
          ... on Node {
            ...fragmentContentfulContentHubPage
          }
          ... on Node {
            ...fragmentContentfulContentHubContentTypePage
          }
          ... on Node {
            ...fragmentContentfulContentHubTopicPage
          }
          ... on Node {
            ...fragmentContentfulContentfulInsuranceTermsPage
          }
          ... on Node {
          ...fragmentContentfulCallToAction
          }
          ... on Node {
          ...fragmentContentfulComponent
          }
          ... on Node {
          ...fragmentContentfulLiftUpLinks
          }
          ... on Node {
          ...fragmentContentfulLiftUpColor
          }
          ... on Node {
          ...fragmentContentfulLiftUpEasyStep
          }
          ... on Node {
          ...fragmentContentfulAccordion
          }
          ... on Node {
          ...fragmentContentfulLiftUpBox
          }
          ... on Node {
          ...fragmentContentfulAsset
          }
          ... on Node {
          ...fragmentContentfulContentHubLiftUps
          }
          ... on Node {
          ...fragmentContentfulEmbeddedNotification
          }
          ... on Node {
            ...fragmentContentfulDynamicSection
          }
          ... on Node {
            ...fragmentContentfulProcessGuide
          }

          ... on ContentfulInsuranceBrochure {
            id
            contentful_id
            __typename
            slug
          }

          ... on ContentfulInsuranceTermsPage {
            id
            contentful_id
            __typename
            slug
          }
        }
      }

      contentAreaBottom {
        raw
        references {
          ... on Node {
            ...fragmentContentfulComponent
          }
          ... on Node {
            ...fragmentContentfulLiftUpLinks
          }
          ... on Node {
            ...fragmentContentfulProcessGuide
          }
        }
      }
      hidden
      noIndex
      enableAbTesting
      showNpsPopup
      contentTopics {
        ...fragmentContentfulContentTopic
      }
    }
  }
`;

export default ProductTemplate;
